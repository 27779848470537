import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function Tile({ item }) {
    const [hover, setHover] = useState(false)
    const [style, setStyle] = useState({
        backgroundImage: 'linear-gradient(0deg, rgba(54, 54, 54, 0.5), rgba(54, 54, 54, 0.8)), url("../images/item-tile.jpg")',
        backgroundSize: 'cover'
    })
    useEffect(() => {
        if (item.hasOwnProperty('link') && hover) {
            setStyle({
                backgroundImage: `linear-gradient(0deg, rgba(54, 54, 54, 0.5), rgba(54, 54, 54, 0.8)), url("${item.tileImg}")`,
                backgroundSize: 'cover'
            })
        } else if (item.hasOwnProperty('link')) {
            setStyle({
                backgroundImage: `linear-gradient(0deg, rgba(44, 130, 121, 0.8), rgba(44, 130, 121, 0.8)), url("${item.tileImg}")`,
                backgroundSize: 'cover'
            })
        } else if (!item.hasOwnProperty('link')) {
            setStyle({});
        }
    }
        // eslint-disable-next-line
        , [hover])

    return (
        <div
            className={`class_img-box`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={item.name}
            style={style}
        >
            <Link to={item.link} style={{textAlign: "center"}}>{item.name} {item.date && item.time && <p>{item.date} {item.time}</p>}</Link>
        </div>
    );
}

export default Tile;