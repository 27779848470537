import WorkshopsSection from "../components/WorkshopsSection";

function Workshops() {
    return (
        <>
            <div className="about-page"  data-testid="workshops-page">
                <WorkshopsSection />
            </div>
        </>
    );
}
export default Workshops; 