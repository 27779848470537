import React, { useContext } from "react";
import LessonTable from '../components/LessonTable';
// const lessons = require('../constants/lessons.json')
import {apiLessonDataContext} from '../context/AppContext'
function Lessenrooster() {
    const [apiLessonData] = useContext(apiLessonDataContext);
    const lessons = apiLessonData
    return (
        <div className="about-page"  data-testid="lessenrooster-page">
            <section className="class_section">
                <div className="container">
                    <h2 style={{ marginTop: '50px' }}>Lessenrooster</h2>
                    <h3>Onze danscursussen lopen van 9 september tot 1 juni.</h3>
                    <h3>Elke eerste dansles is gratis en vrijblijvend.</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-detail" >
                                <LessonTable lessons={lessons} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Lessenrooster;
