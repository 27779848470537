import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext"
function LessonTable(props) {
    const { lessons } = props
    const [isMobile] = useContext(AppContext);


    const extractedSchedules = lessons.reduce((schedules, lesson) => {
        lesson.schedules.forEach(schedule => {
            schedules.push({
                name: lesson.name,
                link: lesson.link,
                img: lesson.img,
                tileImg: lesson.tileImg,
                ...schedule
            });
        });
        return schedules;
    }, []).sort((a, b) => {
        if (a.day === b.day) {
            return Number(a.startHour.replace(':', '')) - Number(b.startHour.replace(':', ''));
        }
        return a.day - b.day;
    });

    const lessonDays = Array.from(new Set(extractedSchedules.map(item => item.day)));



    const days = {
        1: "Maandag",
        2: "Dinsdag",
        3: "Woensdag",
        4: "Donderdag",
        5: "Vrijdag",
        6: "Zaterdag",
        7: "Zondag",
    };
    if (isMobile) {

        return (<>
            {lessonDays.map((day, index) => (
                <div key={"table-" + index}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h1>{days[day]}</h1>
                        <Link to="https://www.ledenbeheer.be/public/tmdancecompany">
                            <button className="btn btn-primary">Inschrijven</button>
                        </Link>
                    </div>
                    <table className="table-striped table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ "maxWidth": "100px" }}>Uur</th>
                                <th scope="col" style={{ "minWidth": "150px" }}>Naam</th>
                                {/* <th scope="col">Doelgroep</th> */}
                                <th scope="col" style={{ "minWidth": "70px", "maxWidth": "80px" }}>Prijs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {extractedSchedules.filter(i => i.day === day).map((lesson, index) => (
                                <tr key={`lesson-table-${index}`}>
                                    <td style={{ "maxWidth": "100px" }}>{lesson.startHour} - {lesson.endHour}</td>
                                    <td><Link to={"/lessen/" + lesson.link}>{lesson.scheduleTitle} ({lesson.age})</Link></td>
                                    {/* <td>{lesson.age}</td> */}
                                    <td style={{ "minWidth": "70px", "maxWidth": "80px" }}>€ {lesson.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </>

        );
    } else {
        return (<>
            <table className="table-striped table">
                <thead>
                    <tr>
                        <th scope="col" style={{ "maxWidth": "100px" }}>Dag</th>
                        <th scope="col" style={{ "maxWidth": "100px" }}>Uur</th>
                        <th scope="col" style={{ "minWidth": "150px" }}>Naam</th>
                        <th scope="col">Inschrijven</th>
                        <th scope="col">Doelgroep</th>
                        <th scope="col" style={{ "minWidth": "70px", "maxWidth": "80px" }}>Prijs</th>
                    </tr>
                </thead>
                <tbody>
                    {extractedSchedules.map((lesson, index) => (
                        <tr key={`lesson-table-${index}`}>
                            <td>{days[lesson.day]}</td>
                            <td style={{ "maxWidth": "100px" }}>{lesson.startHour} - {lesson.endHour}</td>
                            <td><Link to={"/lessen/" + lesson.link}>{lesson.scheduleTitle}</Link></td>
                            <td><Link to="https://www.ledenbeheer.be/public/tmdancecompany">Inschrijven</Link></td>
                            <td>{lesson.age}</td>
                            <td style={{ "minWidth": "70px", "maxWidth": "80px" }}>€ {lesson.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>

        );
    }
}

export default LessonTable