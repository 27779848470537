
import { Link } from 'react-router-dom';
import AboutUsSection from '../components/AboutUsSection';

function Main() {
    return (
        <>
        
            <section className="slider_section position-relative" data-testid="main-page">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="container slider_item-box">
                                <div className="row pt-5">
                                    <div className="col-md-6">
                                        <div className="slider-box-detail">
                                            <h1>
                                                TM Dance Company
                                            </h1>
                                            <p>Splinternieuw in Rillaar</p>
                                            <div>
                                                <Link to="lessen">
                                                    <div>
                                                        <span> Lessen </span>
                                                        <span>
                                                            <img src="images/right.png" alt="" style={{ width: '18px' }} />
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="slider_box-img">
                                            <div className="slider_item_img-box">
                                                <img src="images/tm-logo-bg-black-600x600.png" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <AboutUsSection />
            
        </>
    )
}

export default Main;