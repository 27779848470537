import AboutUsSection from "../components/AboutUsSection";


function AboutUs() {
    return (
        <>
            <div className="about-page"  data-testid="about-us-page">
                <AboutUsSection />
            </div>
        </>
    );
}
export default AboutUs; 