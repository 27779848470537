import React, {useContext} from "react";
import TilesMatrix from "../components/TilesMatrix";
import {apiLessonDataContext} from '../context/AppContext'
function Lessons() {
    const [apiLessonData] = useContext(apiLessonDataContext);
    return (
        <div data-testid="lessons-page">
            <TilesMatrix data={apiLessonData} base_path="lessen" page_title="Onze lessen" />
        </div >
    )
}

export default Lessons;