import React, { useContext } from "react";
import TilesMatrix from "../components/TilesMatrix";
import { apiEventDataContext } from '../context/AppContext'

function Events() {
    const [apiEventData] = useContext(apiEventDataContext);
    return (
        <div data-testid="evenementen-page">
            <TilesMatrix data={apiEventData} base_path="evenementen" page_title="Onze evenementen" />
        </div >
    )
}

export default Events;