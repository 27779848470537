
import React from "react";
import { useNavigate } from 'react-router-dom';
import Tile from "./Tile";


function TilesMatrix({ data, base_path, page_title }) {

  const splitData = []
  for (let i = 0; i < data.length; i += 4) {
    const slice = data.slice(i, i + 4)
    if (slice.length < 4) {
      // Add empty objects to fill up the array
      const diff = 4 - slice.length
      for (let j = 0; j < diff; j++) {
        slice.push({})
      }
    }
    splitData.push(slice)
  }
  const navigate = useNavigate();
  function handleClick(link) {
    navigate(`/${base_path}/` + link);
  }
  return (
    <section className="class_section py-5 my-5">
      <h2>{page_title}</h2>
      <div className="container pb-5">
        {splitData.map((sliceData, i) => (
          <div key={`${base_path}-tile-${i}`} className="d-flex justify-content-between flex-wrap" style={{ 'marginBottom': '20px' }}>
            {
              sliceData.map((item, index) => (
                <div key={`${base_path}-${index}`} className="mt-4 mx-auto mx-sm-0 mt-lg-0" onClick={() => handleClick(item.link)}>
                  <Tile item={item} />
                </div>
              ))
            }
          </div>
        ))}
      </div>

    </section >
  );

}


export default TilesMatrix