import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const AppContext = createContext();
export const apiLessonDataContext = createContext();
export const apiEventDataContext = createContext();

export default function AppProvider({ children, test }) {
    const [isMobile, setIsMobile] = useState(false);
    const [apiLessonData, setapiLessonData] = useState(() => {
        // Get cached data from local storage
        const cachedData = localStorage.getItem("apiLessonData");
        return cachedData ? JSON.parse(cachedData) : [];
    });
    const [apiEventData, setapiEventData] = useState(() => {
        // Get cached data from local storage
        const cachedData = localStorage.getItem("apiEventData");
        return cachedData ? JSON.parse(cachedData) : [];
    });

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }

        // Run handleResize() every 1 second
        const timer = setInterval(() => {
            handleResize();
        }, 1000);

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener and timer on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        const url = process.env.REACT_APP_API || '';
        if (!test) {
            axios.get(`${url}/api/lessons`).then((response) => {
                setapiLessonData(response.data);
                localStorage.setItem("apiLessonData", JSON.stringify(response.data));
            }).catch((error) => {
                console.log(error);
                setapiLessonData({
                    "name": "Fout bij het laden van de lessen",
                    "link": "error",
                    "body": "Er is een fout opgetreden bij het laden van de lessen. Probeer het later opnieuw.",
                    "img": "",
                    "tileImg": "",
                    "schedules": []
                })
            })
        }
    }
        , [test]);

    useEffect(() => {
        const url = process.env.REACT_APP_API || '';
        if (!test) {
            axios.get(`${url}/api/events`).then((response) => {
                setapiEventData(response.data);
                localStorage.setItem("apiEventData", JSON.stringify(response.data));
            }).catch((error) => {
                console.log(error);
                setapiEventData({
                    "eventName": "Fout bij het laden van de evenementen",
                    "link": "error",
                    "body": "Er is een fout opgetreden bij het laden van de lessen. Probeer het later opnieuw.",
                    "img": "",
                    "tileImg": ""
                })
            })
        }
    }
        , [test]);

    return (
        <AppContext.Provider value={[isMobile]}>
            <apiLessonDataContext.Provider value={[apiLessonData]}>
                <apiEventDataContext.Provider value={[apiEventData]}>
                    {children}
                </apiEventDataContext.Provider>
            </apiLessonDataContext.Provider>
        </AppContext.Provider>
    );
}
