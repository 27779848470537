// import logo from './logo.svg';
import React from 'react';
import './css/bootstrap.css'
import './css/style.css'
import './css/responsive.css'
import './css/general.css'
import { BrowserRouter, MemoryRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main"
import Layout from './templates/Layout';
import NoPage from "./pages/NoPage";
import Lesson from './pages/Lesson';
import Lessons from './pages/Lessons';
import Lessenrooster from './pages/Lessenrooster';
import AboutUs from './pages/AboutUs';
import Workshops from './pages/Workshops';
import ScrollToTop from './components/ScrollToTop';
import Events from "./pages/Events"
import Event from "./pages/Event"
// import Audition from './pages/Audition';
function App({test}) {
  const Router = test ? React.Fragment : (process.env.NODE_ENV === 'test' ? MemoryRouter : BrowserRouter);
  return (
    <Router>
      <ScrollToTop test={test} />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="evenementen" element={<Events />} />
          <Route path="evenementen/:eventId" element={<Event />} />
          <Route path="lessen" element={<Lessons />} />
          <Route path="lessen/:lessonId" element={<Lesson />} />
          <Route path="lessenrooster" element={<Lessenrooster />} />
          <Route path="over-ons" element={<AboutUs />} />
          <Route path="workshops" element={<Workshops />} />
          {/* <Route path="auditie" element={<Audition />} /> */}
          <Route index element={<Main />} />
          <Route path="*" element={<NoPage />} />
        </Route>

      </Routes>
    </Router>
  );
}

export default App;
